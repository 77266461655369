import React from 'react';

import Layout from '../components/Layout';
import UserMessage from '../components/UserMessage';

const ContactPage = () => (
  <Layout>
    <UserMessage type="thanks" />
  </Layout>
);

export default ContactPage;
